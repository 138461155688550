import React, {useContext, useEffect } from "react";
import { Box } from "@mui/material";
import SimResultsList from "../components/SimResultsList";
import { ResultsContext } from "../ResultsContext";
import { ModelContext } from "../ModelContext";

const AutoListPage = () => {
  const {
    autoResults,
    autoLoading,
    autoError,
    filteredAutoResults,
    setFilteredAutoResults
  } = useContext(ResultsContext);

  // const { modelSelected } = React.useContext(ModelContext);

  if (autoLoading) return <p>Loading...</p>;
  if (autoError) return <p>Error: {autoError.message}</p>;

  return (
    <Box sx={{ p: 2, border: 0 }}>
      <SimResultsList
        type_of_claim={"Auto"}
        data={filteredAutoResults}
        setFilteredResults={setFilteredAutoResults}
        originalData={autoResults}
      />
    </Box>
  );
};

export default AutoListPage;
