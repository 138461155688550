import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

function DrawerButton({ closeDrawer, unique_key, icon, path_to, text }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(location.pathname === path_to);

  useEffect(() => {
    setIsActive(location.pathname === path_to);
  }, [location.pathname, path_to]);

  const handleClick = () => {
    setIsActive(true);
    closeDrawer();
    navigate(path_to);
  };

  return (
    <ListItem key={unique_key} disablePadding>
      <ListItemButton
        onClick={handleClick}
        sx={{
          borderRadius: 4,
          bgcolor: isActive ? "secondary.main" : "background.default",
          "&:hover": {
            backgroundColor: "grey",
            color: isActive ? "white" : "secondary.main",
          },
          color: isActive ? "background.default" : "secondary.main",
        }}
        // component={Link}
        // to={path_to}
      >
        <ListItemIcon
          sx={{
            p: 0,
            m: 0,
            color: isActive ? "white" : "secondary.main",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}

export default DrawerButton;
