import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import MuiMarkdown from "mui-markdown";
import about_markdown from "./about_content.md";
import { useState } from "react";

function AboutPage() {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    fetch(about_markdown)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text));
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Stack sx={{ border: 5, borderColor: "lightgray", borderRadius: 5 }}>
        <Typography
          align="center"
          sx={{
            pb: 1,
            borderBottom: 3,
            width: "100%",
            bgcolor: "lightgray",
            fontSize: 26,
            fontWeight: "bold",
            color: "secondary.main",
            borderColor: "secondary.main",
          }}
        >
          About the Simulation
        </Typography>
        <Box
          sx={{
            bgcolor: "white",
            p: 2,
            "& h1": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h2": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h3": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h4": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h5": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h6": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& p": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 1, // Adds space below h4 headers
            },
            "& table": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
              border: "1px solid #ddd", // Adds border to table cells
              width: "100%", // Makes table width 100%
              borderCollapse: "collapse", // Ensures borders are collapsed
            },
            "& th, & td": {
              border: "1px solid #ddd", // Adds border to table cells
              padding: "8px", // Adds padding to table cells
            },
            "& th": {
              backgroundColor: "lightgray", // Adds background color to table headers
            },
            "& img": {
              border: "1px solid #aaa", // Adds border to table cells
              display: "block", // Ensures the image is treated as a block element
              // margin: "16px auto", // Adds margin on top and bottom, centers the image horizontally
              marginTop: 2, // Adds padding above the image
              marginBottom: 2, // Adds padding below the image
            },
          }}
        >
          <MuiMarkdown>{markdownContent}</MuiMarkdown>
        </Box>
      </Stack>
    </Box>
  );
}

export default AboutPage;
