import * as React from "react";
import { Box } from "@mui/material";

import SimResultsList from "../components/SimResultsList";
import { ResultsContext } from "../ResultsContext";

const FireListPage = () => {
  const {
    fireResults,
    fireLoading,
    fireError,
    filteredFireResults,
    setFilteredFireResults,
  } = React.useContext(ResultsContext);

  if (fireLoading) return <p>Loading...</p>;
  if (fireError) return <p>Error: {fireError.message}</p>;

  return (
    <Box sx={{ p: 2 }}>
      <SimResultsList
        type_of_claim={"Fire"}
        data={filteredFireResults}
        setFilteredResults={setFilteredFireResults}
        originalData={fireResults}
      />
    </Box>
  );
};

export default FireListPage;
