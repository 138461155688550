// SortFilterContext.js
import React, { createContext, useContext, useState } from "react";
import { ModelContext } from "./ModelContext";

// Create a context for the handle_new_mail_mode_change function
const SortFilterContext = createContext();

export const useSortFilter = () => useContext(SortFilterContext);

export const SortFilterProvider = ({ children }) => {
  const [ascendingSort, setAscendingSort] = React.useState(true);
  const [sortedBy, setSortedBy] = useState("id");
  const [textToDisplay, setTextToDisplay] = React.useState("ID");
  const [filters, setFilters] = React.useState({
    valid_docs_only: false,
    pd_tasks_only: false,
    sha_excluded_only: false,
  });
  const { modelSelected } = useContext(ModelContext);
  React.useEffect(() => {
    setFilters({
      valid_docs_only: false,
      pd_tasks_only: false,
      sha_excluded_only: false,
    });
      setAscendingSort(true);
    setSortedBy("id");
    setTextToDisplay("ID");
  }, [modelSelected]);

  return (
    <SortFilterContext.Provider
      value={{
        ascendingSort,
        setAscendingSort,
        sortedBy,
        setSortedBy,
        textToDisplay,
        setTextToDisplay,
        filters,
        setFilters,
      }}
    >
      {children}
    </SortFilterContext.Provider>
  );
};
