export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY_URL,
        redirectUri: process.env.REDIRECT_URL,
    },
  };
  
  export const loginRequest = {
    scopes: ["api://0aaa502c-0a16-4e5c-8657-27b80469eecd/.default"]
  };
  