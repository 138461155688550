// NewMailModeContext.js
import React, { createContext, useContext, useState } from 'react';

// Create a context for the handle_new_mail_mode_change function
const NewMailModeContext = createContext();

export const useNewMailMode = () => useContext(NewMailModeContext);

export const NewMailModeProvider = ({ children }) => {
  const [nm_to_all, setNmToAll] = useState(false);

  const handle_new_mail_mode_change = (new_value) => {
    setNmToAll(new_value);
  };

  return (
    <NewMailModeContext.Provider value={{ nm_to_all, handle_new_mail_mode_change }}>
      {children}
    </NewMailModeContext.Provider>
  );
};