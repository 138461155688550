import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Collapse,
  Stack,
  Modal,
  Button,
  ImageList,
} from "@mui/material";
import { green, grey} from "@mui/material/colors";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxHeight: "90%",
  maxWidth: "1600px",
  backgroundColor: "white",
  border: "1px solid #000",
  borderRadius: 5,
  boxShadow: 10,
  p: 3,
  mt: 2,
};

const outer_header_style = {
  fontSize: 16,
  color: grey[900],
  fontWeight: "bold",
};
const inner_header_style = {
  fontSize: 16,
  color: grey[900],
  fontWeight: "bold",
};

function PerformanceTable({ perf_data, model }) {
  const instanceRowNumCols = 7;

  return (
    <TableContainer sx={{ p: 2 }}>
      <Table size="small" sx={{ border: 2, borderRadius: 3 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "secondary.main" }}>
            <TableCell colSpan={instanceRowNumCols} sx={{ color: "white" }}>
              {model}
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: "lightgray" }}>
            <TableCell />
            <TableCell style={outer_header_style}>Instance Type</TableCell>
            <TableCell style={outer_header_style}>Instance Tier</TableCell>
            <TableCell style={outer_header_style}>vCPU Count</TableCell>
            <TableCell style={outer_header_style}>vGPU Count</TableCell>
            <TableCell style={outer_header_style}>Memory</TableCell>
            <TableCell style={outer_header_style}>Cost per Hour</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {perf_data.map((instance_metrics, index) => (
            <InstanceRow
              key={index}
              instance_metrics={instance_metrics}
              instanceRowNumCols={instanceRowNumCols}
              model={model.toLowerCase()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function InstanceRow({ instance_metrics, instanceRowNumCols, model }) {
  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imgModalOpen, setImageModalOpen] = useState(false);

  const handleImageClick = (imgs_data) => {
    setSelectedImages(imgs_data);
    setImageModalOpen(true);
  };

  const handleCloseImage = () => {
    setImageModalOpen(false);
    setSelectedImages([]);
  };
  return (
    <>
      <TableRow sx={{ border: 0, borderColor: "secondary.main" }}>
        <TableCell sx={{ width: 5, border: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          {instance_metrics.instance_type}
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          {instance_metrics.instance_tier}
        </TableCell>
        <TableCell sx={{ border: 0 }}>{instance_metrics.vCPU_count}</TableCell>
        <TableCell sx={{ border: 0 }}>{instance_metrics.vGPU_count}</TableCell>
        <TableCell sx={{ border: 0 }}>
          {instance_metrics.memory_GiB}GiB
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          {instance_metrics.cost_per_hour}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={instanceRowNumCols}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                border: 0,
                p: 1,
                pl: 1,
                borderBottom: 2,
                borderColor: "gray",
                pb: 2,
              }}
            >
              <Table
                size="small"
                aria-label="additional metrics"
                sx={{ border: 0, borderRadius: 3, bgcolor: green[200] }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={inner_header_style}>
                      # of Threads
                    </TableCell>
                    <TableCell style={inner_header_style}>Delay</TableCell>
                    <TableCell style={inner_header_style}>Duration</TableCell>
                    <TableCell style={inner_header_style}>
                      Transactions/Sec
                    </TableCell>
                    <TableCell style={inner_header_style}>
                      Latency (min | max | avg)
                    </TableCell>
                    <TableCell align="center" style={inner_header_style}>
                      Utilization Charts
                    </TableCell>

                    {/* Add more columns as needed */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instance_metrics.data.map((instance_config, index) => (
                    <TableRow key={index}>
                      <TableCell>{instance_config.number_of_threads}</TableCell>
                      <TableCell>{instance_config.delay_in_seconds}s</TableCell>
                      <TableCell>
                        {instance_config.duration_in_seconds}s
                      </TableCell>
                      <TableCell>
                        {instance_config.number_transactions_per_second}
                      </TableCell>
                      <TableCell>
                        {instance_config.latency.min} |{" "}
                        {instance_config.latency.max} |{" "}
                        {instance_config.latency.avg}
                      </TableCell>

                      <TableCell>
                        <Stack
                          spacing={0.5}
                          direction={"row"}
                          sx={{ justifyContent: "center", display: "flex" }}
                        >
                          {instance_config.imgs.map((img, index) => (
                            <Box
                              onClick={() =>
                                handleImageClick(instance_config.imgs)
                              }
                              component="img"
                              sx={{
                                //   maxWidth: 250,
                                maxHeight: 200,
                                objectFit: "contain",
                                cursor: "pointer",
                                border: 0,
                              }}
                              alt={img.alt}
                              src={`${process.env.PUBLIC_URL}/images/${model}_performance/${instance_metrics.instance_type}/${img.src}`}
                            />
                          ))}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={imgModalOpen}
        onClose={handleCloseImage}
        aria-labelledby="enlarged-image-modal-table"
        aria-describedby="enlarged-image-modal-description-table"
      >
        <Box style={imageModalStyle} sx={{ p: 2 }}>
          <Button
            variant="contained"
            onClick={handleCloseImage}
            sx={{
              borderRadius: 3,
              textTransform: "unset",
              backgroundColor: "primary.main",
            }}
            startIcon={<ClearIcon />}
          >
            CLOSE
          </Button>

        <ImageList cols={2} sx={{ overflow: "auto" }}>
            {selectedImages.map((current_image, index) => (
                <Box
                  component="img"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: 700,
                    objectFit: "contain",
                    cursor: "pointer",
                    border: 2,
                  }}
                  alt={current_image.alt}
                  src={`${process.env.PUBLIC_URL}/images/${model}_performance/${instance_metrics.instance_type}/${current_image.src}`}
                />
            ))}
          </ImageList>
        </Box>
      </Modal>
    </>
  );
}

export default PerformanceTable;

// import {
//   Box,
//   Table,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from "@mui/material";
// import { grey } from "@mui/material/colors";
// import React from "react";

// function PerformanceTable({ perf_data, model }) {
//   const instanceRowNumCols = 6;

//   const style = {
//     fontSize: 16,
//     color: grey[900],
//     fontWeight: "bold",
//   };
//   return (
//     <TableContainer sx={{ p: 2 }}>
//       <Table colspan={instanceRowNumCols} sx={{ border: 2, borderRadius: 3 }}>
//         <TableHead colspan={instanceRowNumCols}>
//           <TableRow sx={{ backgroundColor: "secondary.main" }}>
//             <TableCell sx={{ color: "white" }} colspan={instanceRowNumCols}>
//               {model}
//             </TableCell>
//           </TableRow>
//           <TableRow sx={{ backgroundColor: "lightgray" }}>
//             <TableCell style={style}>Instance Type</TableCell>
//             <TableCell style={style}>Instance Tier</TableCell>
//             <TableCell style={style}>vCPU Count</TableCell>
//             <TableCell style={style}>vGPU Count</TableCell>
//             <TableCell style={style}>Memory</TableCell>
//             <TableCell style={style}>Cost per Hour</TableCell>
//           </TableRow>
//         </TableHead>
//         {perf_data.map((instance_metrics, index) => (
//           <TableRow
//             colspan={instanceRowNumCols}
//             key={index}
//             sx={{ border: 2, borderColor: "secondary.main" }}
//           >
//             <TableCell>{instance_metrics.instance_type}</TableCell>
//             <TableCell>{instance_metrics.instance_tier}</TableCell>
//             <TableCell>{instance_metrics.vCPU_count}</TableCell>
//             <TableCell>{instance_metrics.vGPU_count}</TableCell>
//             <TableCell>{instance_metrics.memory_GiB}GiB</TableCell>
//             <TableCell>{instance_metrics.cost_per_hour}</TableCell>
//           </TableRow>
//         ))}
//       </Table>
//     </TableContainer>
//   );
// }
//
// export default PerformanceTable;
