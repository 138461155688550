import { Box, Stack } from '@mui/material'
import React from 'react'

function PDImage({img1Data,img2Data, task_index}) {

  return (

      <Stack sx={{border:0, alignItems: "center", justifyContent:"center"}} direction={"row"}>
        <Box
          component="img"
          sx={{
            maxWidth: "50%",
            maxHeight: 600,
            objectFit: "contain",
            cursor: "pointer",
            border: 1,
          }}
          alt="Img1"
          src={`data:image/png;base64, ${img1Data}`}
        />
        <Box
          component="img"
          sx={{
            maxHeight: 600,
            maxWidth: "50%",
            objectFit: "contain",
            cursor: "pointer",
            border: 1,
          }}
          alt="Img2"
          src={`data:image/png;base64, ${img2Data}`}
        />
      </Stack>

  )
}

export default PDImage