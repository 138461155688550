import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "./authConfig";


const useFetchData = (url) => {
  const {instance, accounts} = useMsal();
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bearerToken, setBearerToken] = useState("")

  useEffect(() => {
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        setBearerToken(accessTokenResponse.accessToken);
      });
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!bearerToken) {
        return; // Wait until the token is acquired
      }
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const data = await response.json();
        setResults(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, bearerToken]);

  return { results, loading, error };
};

export default useFetchData;