import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useNewMailMode } from "../NewMailModeContext";
import CheckIcon from "@mui/icons-material/Check";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSortFilter } from "../SortFilterContext";
import { ResultsContext } from "../ResultsContext";
import { ModelContext } from "../ModelContext";

function FilterMenu({
  originalData,
  setFilteredResults,
  thresholds,
}) {
  const { nm_to_all } = useNewMailMode();
  const {resetResults} = useContext(ResultsContext);
  const { modelSelected } = useContext(ModelContext);

  const {filters, setFilters} = useSortFilter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [localValidDocsChecked, setLocalValidDocsChecked] = React.useState(
    filters.valid_docs_only || false
  );
  const [localPdsChecked, setLocalPdsChecked] = React.useState(
    filters.pd_tasks_only || false
  );
  const [localShaExcludedChecked, setLocalShaExcludedChecked] = React.useState(
    filters.sha_excluded_only || false
  );
  React.useEffect(() => {
    // console.log("New Mail Mode changed: Applying Filters!")
    applyFilters()
  }, [nm_to_all]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // console.log("Local Filters changed!")
    applyFilters();
  }, [localValidDocsChecked, localPdsChecked, localShaExcludedChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => {
  //   console.log("HERE");
  //   setLocalValidDocsChecked(false);
  //   setLocalPdsChecked(false);
  //   setLocalShaExcludedChecked(false);
  // }, [modelSelected]);

  const applyFilters = () => {
    let filteredResults = [...originalData]; // Create a new array to avoid mutating the original array
    // var filteredResults;

    if (localValidDocsChecked) {
      // console.log("Filtering for valid docs");
      filteredResults = filteredResults.filter(
        (item) => item.num_valid_doc_ids > 0
      );
    }
    if (localPdsChecked) {
      // console.log("Filtering for PDs only");
      if (nm_to_all) {
        filteredResults = filteredResults.filter((item) =>
          Object.values(item.total_tasks.new_mail_to_all).some(
            (value) => value > 0
          )
        );
      } else {
        filteredResults = filteredResults.filter((item) =>
          Object.values(item.total_tasks.new_mail_to_new_mail).some(
            (value) => value > 0
          )
        );
      }
    }
    if (localShaExcludedChecked) {
      // console.log("Filtering for SHA excluded > 0");
      filteredResults = filteredResults.filter(
        (item) => item.num_exact_dup_excluded > 0
      );
    }
    setFilteredResults(filteredResults);
    setFilters({
      valid_docs_only: localValidDocsChecked,
      pd_tasks_only: localPdsChecked,
      sha_excluded_only: localShaExcludedChecked,
    });
    if (!localValidDocsChecked && !localPdsChecked && !localShaExcludedChecked) {
      resetResults();
    }
  };

  //     if (localValidDocsChecked) {
  //       console.log("Filtering for valid docs");
  //       const dataCopy = [...data]; // Create a new array to avoid mutating the original array
  //       const filteredResults = dataCopy.filter(
  //         (item) => item.num_valid_doc_ids > 0
  //       );
  //       // console.log(filteredResults);
  //       setFilteredResults(filteredResults);
  //       setFilters({ valid_docs_only: true, pd_tasks_only: false });
  //     } else {
  //       console.log("Resetting Valid results");
  //       resetResults();
  //     }
  //     if (localPdsChecked) {
  //       console.log("Filtering for PDs only");
  //       const dataCopy = [...data]; // Create a new array to avoid mutating the original array
  //       // const filteredResults = dataCopy.filter(
  //       //   (item) => item.num_valid_doc_ids > 0
  //       // );
  //       var filteredResults;
  //       if (nm_to_all) {
  //         filteredResults = dataCopy.filter((item) =>
  //           Object.values(item.total_tasks.new_mail_to_all).some(
  //             (value) => value > 0
  //           )
  //         );
  //       } else {
  //         filteredResults = dataCopy.filter((item) =>
  //           Object.values(item.total_tasks.new_mail_to_new_mail).some(
  //             (value) => value > 0
  //           )
  //         );
  //       }
  //       console.log(filteredResults);
  //       setFilteredResults(filteredResults);
  //       setFilters({ valid_docs_only: false, pd_tasks_only: true });
  //     } else {
  //       console.log("Resetting PD results");
  //       resetResults();
  //     }
  //     handleMenuClose();
  //   };

  //   const handleLocalChange = (event, name) => {
  //     console.log(event)
  //     console.log(event.target.checked)
  //     setvalidDocsChecked(event.target.checked);
  //     if (event.target.checked) {
  //       console.log("Filtering for valid docs");
  //       const dataCopy = [...data]; // Create a new array to avoid mutating the original array
  //       const filteredResults = dataCopy.filter(
  //         (item) => item.num_valid_doc_ids > 0
  //       );
  //       // console.log(filteredResults);
  //       setFilteredResults(filteredResults);
  //       setFilters({ valid_docs_only: true, pd_tasks_only: false });
  //     } else {
  //       console.log("Resetting results");
  //       resetResults();
  //     }

  //     handleMenuClose();
  //   };
  return (
    <Box sx={{ pl: 0, display: "flex", justifyContent: "left" }}>
      <Stack>
        <Button
          startIcon={<FilterListIcon />}
          onClick={handleMenuClick}
          variant="contained"
          sx={{
            width: 110,
            height: 35,
            // borderColor: "secondary.main",
            color: "white",
            borderRadius: 3,
            textTransform: "unset",
            backgroundColor: "secondary.main",
          }}
        >
          FILTER
        </Button>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            pt: 1,
            display: "flex",
            // flexDirection: { xs: "column", lg: "row", alignItems: "center" },
          }}
        >
          {filters.sha_excluded_only ? (
            <Box
              sx={{
                fontWeight: "bold",
                bgcolor: orange[600],
                pr: 1,
                // mt: { xs: 0.5, lg: 0 },
                fontSize: { xs: 10, sm: 11, md: 12 },
                border: 0,
                borderRadius: 3,
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                height: 30,
              }}
            >
              <IconButton
                onClick={() => setLocalShaExcludedChecked(false)}
                size="small"
              >
                <HighlightOffIcon
                  size="small"
                  sx={{ color: "black", fontSize: 20 }}
                />
              </IconButton>
              {"SHA Excluded > 1"}
            </Box>
          ) : (
            ""
          )}
          {filters.pd_tasks_only ? (
            <Box
              sx={{
                fontWeight: "bold",
                bgcolor: orange[600],
                pr: 1,
                fontSize: { xs: 10, sm: 11, md: 12 },
                border: 0,
                borderRadius: 3,
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                height: 30,
              }}
            >
              <IconButton
                onClick={() => setLocalPdsChecked(false)}
                size="small"
              >
                <HighlightOffIcon
                  size="small"
                  sx={{ color: "black", fontSize: 20 }}
                />
              </IconButton>
              {"Similar Files Found > 1"}
            </Box>
          ) : (
            ""
          )}
           
        </Stack>
      </Stack>
      <Menu
        id="filter-menu"
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        elevation={20}
      >
        <Typography
          sx={{ borderBottom: 1, fontWeight: "bold", pb: 1 }}
          align="center"
        >
          Filter By:
        </Typography>
        <Stack>
          <FormControlLabel
            sx={{ px: 4 }}
            value="end"
            control={
              <Checkbox
                checked={filters.pd_tasks_only}
                onChange={() => setLocalPdsChecked(!localPdsChecked)}
                color="secondary.main"
                sx={{ color: "secondary.main" }}
                inputProps={{ "aria-label": "pd-tasks-only" }}
              />
            }
            label="Claims with Similar Files Found > 1"
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ px: 4 }}
            value="end"
            control={
              <Checkbox
                checked={filters.sha_excluded_only}
                onChange={() =>
                  setLocalShaExcludedChecked(!localShaExcludedChecked)
                }
                color="secondary.main"
                sx={{ color: "secondary.main" }}
                inputProps={{ "aria-label": "valid-docs-only" }}
              />
            }
            label="Claims with SHA Excluded > 1"
            labelPlacement="end"
          />
          
          <Stack sx={{ px: 2, pt: 1 }} spacing={1} direction={"row"}>
            <Button
              startIcon={<RestartAltIcon />}
              variant="outlined"
              onClick={() => {
                resetResults();
                setLocalPdsChecked(false);
                setLocalValidDocsChecked(false);
                handleMenuClose();
              }}
              sx={{
                mb: 0,
                borderRadius: 3,
                borderColor: "primary.main",
                color: "primary.main",
                width: "50%",
              }}
            >
              RESET
            </Button>
            <Button
              startIcon={<CheckIcon />}
              onClick={handleMenuClose}
              variant="contained"
              sx={{
                mb: 0,
                bgcolor: "secondary.main",
                color: "white",
                borderRadius: 3,
                width: "50%",
              }}
            >
              DONE
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </Box>
  );
}

export default FilterMenu;
