import React, { useState, useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { blue, orange } from "@mui/material/colors";
import SummaryDashboard from "../summary_components/SummaryDashboard";
import { ModelContext } from "../ModelContext";
import useFetchData from "../useFetchData";

const API_RESULTS_BASE_URL =
  "https://zqhmgl85wh.execute-api.us-east-1.amazonaws.com/simulation/results";

const SummaryPage = () => {
  const { modelSelected } = useContext(ModelContext);

  const [summaryData, setSummaryData] = useState({
    dinov2: {
      auto: null,
      fire: null,
      loading: true,
      error: null,
    },
    titan: {
      auto: null,
      fire: null,
      loading: true,
      error: null,
    },
  });

  const autoSummaryDino = useFetchData(
    `${API_RESULTS_BASE_URL}?lob=auto&model=dinov2&type=summary`
  );
  const fireSummaryDino = useFetchData(
    `${API_RESULTS_BASE_URL}?lob=fire&model=dinov2&type=summary`
  );
  const autoSummaryTitan = useFetchData(
    `${API_RESULTS_BASE_URL}?lob=auto&model=titan&type=summary`
  );
  const fireSummaryTitan = useFetchData(
    `${API_RESULTS_BASE_URL}?lob=fire&model=titan&type=summary`
  );
  //
  React.useEffect(() => {
    if (autoSummaryDino.results && fireSummaryDino.results) {
      setSummaryData((prevData) => ({
        ...prevData,
        dinov2: {
          auto: autoSummaryDino.results,
          fire: fireSummaryDino.results,
        },
      }));
    }
  }, [autoSummaryDino.results, fireSummaryDino.results]);

  React.useEffect(() => {
    if (autoSummaryTitan.results && fireSummaryTitan.results) {
      setSummaryData((prevData) => ({
        ...prevData,
        titan: {
          auto: autoSummaryTitan.results,
          fire: fireSummaryTitan.results,
        },
      }));
    }
  }, [autoSummaryTitan.results, fireSummaryTitan.results]);

  const currentModelData = summaryData[modelSelected];

  if (currentModelData.loading) return <p>Loading...</p>;
  if (currentModelData.error) return <p>Error: {currentModelData.error.message}</p>;

  const { auto, fire } = currentModelData;

  var autoThresholds = [];
  var fireThresholds = [];
  var autoTasksChartData = [];
  var fireTasksChartData = [];
  var autoMirrorChartData = [];
  var fireRoofChartData = [];
  var autoNewDocMatchMultipleDocsChartData = [];
  var fireNewDocMatchMultipleDocsChartData = [];

  if (auto && fire) {
    autoThresholds = Object.keys(
      auto.sum_tasks_new_mail_to_new_mail
    ).map((key) => `0.${key}`);

    fireThresholds = Object.keys(
      fire.sum_tasks_new_mail_to_new_mail
    ).map((key) => `0.${key}`);

    // Prepare data for recharts (TASK AUTO/FIRE)
    autoTasksChartData = autoThresholds.map((threshold, index) => ({
      name: threshold,
      "New Mail to New Mail": Object.values(
        auto.sum_tasks_new_mail_to_new_mail
      )[index],
      "New Mail to All": Object.values(auto.sum_tasks_new_mail_to_all)[index],
    }));

    fireTasksChartData = fireThresholds.map((threshold, index) => ({
      name: threshold,
      "New Mail to New Mail": Object.values(
        fire.sum_tasks_new_mail_to_new_mail
      )[index],
      "New Mail to All": Object.values(fire.sum_tasks_new_mail_to_all)[index],
    }));

    // Prepare data for recharts (Exclusions MIRROR/ROOF)
    autoMirrorChartData = autoThresholds.map((threshold, index) => ({
      name: threshold,
      "New Mail to New Mail": Object.values(
        auto.sum_mirror_excluded_new_mail_to_new_mail
      )[index],
      "New Mail to All": Object.values(
        auto.sum_mirror_excluded_new_mail_to_all
      )[index],
    }));

    fireRoofChartData = fireThresholds.map((threshold, index) => ({
      name: threshold,
      "New Mail to New Mail": Object.values(
        fire.sum_roof_excluded_new_mail_to_new_mail
      )[index],
      "New Mail to All": Object.values(
        fire.sum_roof_excluded_new_mail_to_all
      )[index],
    }));

    // Prepare data for recharts (Num times a new doc matched multiple existing)
    autoNewDocMatchMultipleDocsChartData = autoThresholds.map((threshold, index) => ({  
      name: threshold,
      "New Mail to New Mail": Object.values(
        auto.sum_num_times_new_doc_matched_multiple_docs_on_claim_new_mail_to_new_mail
      )[index],
      "New Mail to All": Object.values(
        auto.sum_num_times_new_doc_matched_multiple_docs_on_claim_new_mail_to_all
      )[index],
    }));

    fireNewDocMatchMultipleDocsChartData = fireThresholds.map((threshold, index) => ({
      name: threshold,
      "New Mail to New Mail": Object.values(
        fire.sum_num_times_new_doc_matched_multiple_docs_on_claim_new_mail_to_new_mail
      )[index],
      "New Mail to All": Object.values(
        fire.sum_num_times_new_doc_matched_multiple_docs_on_claim_new_mail_to_all
      )[index],
    }));
  }


  return (
    <Box sx={{ p: 2 }}>
      <Stack
        sx={{
          borderRadius: 5,
          border: 5,
          borderColor: "lightgray",
          // fontSize: 20,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Typography
          align="center"
          sx={{
            color: "secondary.main",
            border: 0,
            pt: 1,
            backgroundColor: "lightgray",
            // pl: 55,
            fontSize: 26,
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Simulation Summary {" \u2022 "}{" "}
          {modelSelected === "dinov2" ? "DinoV2" : "Titan"}
        </Typography>
        <Stack
          sx={{
            pb: 2,
            backgroundColor: "lightgray",
            justifyContent: "center",
            display: "flex",
            borderBottom: 3,
            borderColor: "secondary.main",
          }}
          direction={"row"}
          spacing={1}
        >
          <Typography
            // align="center"
            sx={{
              color: blue[900],
              fontSize: 24,
              fontWeight: "bold",
              // width: "100%",
            }}
          >
            681 Auto
          </Typography>
          <Typography
            align="left"
            sx={{
              color: "secondary.main",
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            {"\u2022"}
          </Typography>
          <Typography
            align="left"
            sx={{
              color: orange[900],
              fontSize: 24,
              fontWeight: "bold",
              // width: "100%",
            }}
          >
            778 Fire
          </Typography>
        </Stack>
        <SummaryDashboard
          autoSummary={auto}
          fireSummary={fire}
          autoThresholds={autoThresholds}
          fireThresholds={fireThresholds}
          autoTasksChartData={autoTasksChartData}
          fireTasksChartData={fireTasksChartData}
          autoMirrorChartData={autoMirrorChartData}
          fireRoofChartData={fireRoofChartData}
          autoNewDocMatchMultipleDocsChartData={autoNewDocMatchMultipleDocsChartData}
          fireNewDocMatchMultipleDocsChartData={fireNewDocMatchMultipleDocsChartData}
        />
      </Stack>
    </Box>
  );
};

export default SummaryPage;
