import {
  Box,
  Button,
  Divider,
  Grid2,
  Grow,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ClaimRow from "../components/ClaimRow";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClaimDetailsByDay from "../components/ClaimDetailsByDay";
import { useNewMailMode } from "../NewMailModeContext";
import NewMailModeToggle from "../components/NewMailModeToggle";
import { red } from "@mui/material/colors";
import { ModelContext } from "../ModelContext";

function DetailsPage() {
  const { claim_id } = useParams(); // Extract claim_id from the route parameters
  const { nm_to_all } = useNewMailMode();
  const { modelSelected } = useContext(ModelContext);

  const location = useLocation();
  const state = location.state || {}; // Provide a default empty object if location.state is null
  const { item, back_link } = state;
  // Check if location.state exists and has item
  // const item = location.state ? location.state : null;
  if (!item) {
    // Handle the case where item is not available
    return (
      <Box>
        <Typography variant="h6">No Claim Details Available</Typography>
      </Box>
    );
  }

  // const thresholds = Object.entries(item.total_tasks.new_mail_to_new_mail);
  const thresholds = nm_to_all
    ? Object.entries(item.total_tasks.new_mail_to_all)
    : Object.entries(item.total_tasks.new_mail_to_new_mail);

  const columnsCount = 2 + Object.keys(thresholds).length;

  return (
    <Grow in={true}>
      <Box sx={{ p: 2, border: 0 }}>
        <TableContainer
          sx={{
            border: 5,
            borderRadius: 3,
            borderColor: "lightgray",
            width: "100%",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  size="small"
                  colSpan={columnsCount}
                  align="center"
                  sx={{
                    bgcolor: "lightgray",
                    borderBottom: 3,
                    borderColor: "secondary.main",
                    // borderColor: "lightgray",
                    py: 1,
                  }}
                >
                  <Grid2 container spacing={1} sx={{ border: 0 }}>
                    <Grid2
                      size="grow"
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        component={Link}
                        to={back_link}
                        variant="contained"
                        sx={{
                          height: 40,
                          borderRadius: 3,
                          textTransform: "unset",
                          backgroundColor: red[800],
                        }}
                        startIcon={<ArrowBackIosIcon />}
                      >
                        BACK
                      </Button>
                    </Grid2>
                    <Grid2
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "secondary.main",
                      }}
                    >
                      <Stack spacing={1}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 26 }}>
                          {claim_id}
                        </Typography>
                        <Stack direction={"row"} spacing={1}>
                          <Typography
                            sx={{
                              textTransform: "capitalize",

                              fontSize: 16,
                            }}
                          >
                            {item.type_of_claim}
                          </Typography>
                          <Typography>{" \u2022 "}</Typography>
                          <Typography sx={{ fontSize: 16 }}>
                            {nm_to_all
                              ? "New Mail to All"
                              : "New Mail to New Mail"}
                          </Typography>
                          <Typography>{" \u2022 "}</Typography>
                          <Typography
                            sx={{ fontSize: 16, color: "secondary.main" }}
                          >
                            {modelSelected === "dinov2" ? "DinoV2" : "Titan"}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid2>
                    <Grid2
                      size="grow"
                      sx={{
                        justifyContent: "right",
                        display: "flex",
                        alignItems: "right",
                      }}
                    >
                      <NewMailModeToggle />
                    </Grid2>
                  </Grid2>
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell colSpan={columnsCount} sx={{ p: 0, border: 0 }}>
                  <Divider sx={{ py: 0, width: "100%", color: "gray" }}>
                    <Stack direction={"row"}>
                      <Typography sx={{ fontSize: 16 }}>Claim ID:</Typography>
                      <Typography
                        sx={{
                          py: 0,
                          px: 0.5,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        {claim_id}
                      </Typography>
                      <Typography sx={{textTransform: "capitalize", pr:0.5, fontSize: 16 }}>
                        --- { item.type_of_claim} 
                      </Typography>
                      <Typography sx={{ fontSize: 16 }}>
                        --- {nm_to_all ? "New Mail to All" : "New Mail to New Mail"}
                      </Typography>
                    </Stack>
                  </Divider>
                </TableCell>
              </TableRow> */}
            </TableHead>
            <TableBody>
              <ClaimRow
                columnsCount={columnsCount}
                item={item}
                dividerText={"Details by day"}
                nm_to_all={nm_to_all}
              />
            </TableBody>
          </Table>
          <Divider />

          <ClaimDetailsByDay
            details_by_day={item.day_info}
            thresholds={thresholds}
            claim_id={claim_id}
            nm_to_all={nm_to_all}
          />
        </TableContainer>
      </Box>
    </Grow>
  );
}

export default DetailsPage;
