// src/contexts/ModelContext.js
import React, { createContext, useState } from "react";

export const ModelContext = createContext();

const ModelProvider = ({ children }) => {
  const [modelSelected, setModelSelected] = useState("dinov2");

  return (
    <ModelContext.Provider
      value={{
        modelSelected,
        setModelSelected,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};

export default ModelProvider;
