import React from 'react';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./authConfig";
import { createRoot } from 'react-dom/client';

const pca = new PublicClientApplication(msalConfig);

const AppWithAuthentication = () => (
  <MsalProvider instance={pca}>
      <App />
  </MsalProvider>
);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <AppWithAuthentication />
  </React.StrictMode>
);
// ReactDOM.render(<AppWithAuthentication />, document.getElementById("root"));
