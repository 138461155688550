import {
  Box,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { useNewMailMode } from "../NewMailModeContext";
import Radio from "@mui/material/Radio";

function NewMailModeToggle() {
  const { nm_to_all, handle_new_mail_mode_change } = useNewMailMode();

  return (
    <Box>
      <RadioGroup
        aria-labelledby="New Mail Mode Choices"
        name="New Mail Mode Choices"
        value={nm_to_all}
        onChange={() => handle_new_mail_mode_change(!nm_to_all)}
        
      >
        <FormControlLabel
          value={false}
         
          control={
            <Radio
              sx={{ p: 0.5,color: "secondary.main" }}
              color="secondary.main"
            />
          }
          label="New Mail to New Mail"
        />
        <FormControlLabel
          value={true}
          control={
            <Radio
              sx={{ p: 0.5, color: "secondary.main" }}
              color="secondary.main"
            />
          }
          label="New Mail to All"
        />
      </RadioGroup>
    </Box>
  );
}

export default NewMailModeToggle;
