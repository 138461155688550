import { Stack, Typography } from "@mui/material";
import React from "react";

const all_2_all_data = {
  dinov2: {
    auto: {
      890: "13.31%",
      900: "11.28%",
      917: "7.79%",
    },
    fire: {
      900: "6.05%",
      907: "5.02%",
      915: "3.96%",
    }
  },
  titan: {
    auto: {
      920: "22.03%",
      930: "15.74%",
      940: "10.79%",
    },
    fire: {
      930: "12.92%",
      940: "8.41%",
      950: "5.14%",
    }
  },
};

function CustomPercentTable({
  threshold,
  dataSummary,
  color1,
  color2,
  color3="gray",
  borderRight,
  type_of_claim,
  model,
}) {
  const nm_2_all_sum_tasks =
    dataSummary.sum_tasks_new_mail_to_all[threshold.slice(2)];
  const nm_2_nm_sum_tasks =
    dataSummary.sum_tasks_new_mail_to_new_mail[threshold.slice(2)];
  console.log(threshold)
  console.log(model)
  console.log(type_of_claim)
  console.log(all_2_all_data[model.toLowerCase()][type_of_claim][threshold.slice(2)])
  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRight: borderRight,
        borderColor: "gray",
        px: 1,
      }}
    >
      <Typography
        align="center"
        sx={{
          fontSize: 16,
          color: "gray",
          fontWeight: "bold",
          borderBottom: 1,
          pb: 0.5,
          width: "100%",
          borderColor: "gray",
        }}
      >
        {threshold}
      </Typography>
      <Typography
        align="center"
        sx={{
          py: 0.5,
          px: 1,
          fontSize: { xs: 14, sm: 22, md: 24 },
          color: color1,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {(
          (nm_2_all_sum_tasks / dataSummary.sum_images_for_doc_ids) *
          100
        ).toFixed(2) + "%"}
      </Typography>
      <Typography
        align="center"
        sx={{
          py: 0.5,
          px: 1,
          fontSize: { xs: 14, sm: 22, md: 24 },
          color: color2,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {(
          (nm_2_nm_sum_tasks / dataSummary.sum_images_for_doc_ids) *
          100
        ).toFixed(2) + "%"}
      </Typography>
      <Typography
        align="center"
        sx={{
          py: 0.5,
          px: 1,
          fontSize: { xs: 14, sm: 22, md: 24 },
          color: color3,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {all_2_all_data[model.toLowerCase()][type_of_claim][threshold.slice(2)]}
      </Typography>
      {/* <CustomKPI
        title={threshold}
        value={
          (
            (nm_2_all_sum_tasks / dataSummary.sum_images_for_doc_ids) *
            100
          ).toFixed(2) + "%"
        }
        // text_below={threshold}
        chip_color="background.main"
        text_color={color1}
        value_color={color1}
      />
      <CustomKPI
        // title={threshold}
        value={
          (
            (nm_2_nm_sum_tasks / dataSummary.sum_images_for_doc_ids) *
            100
          ).toFixed(2) + "%"
        }
        // text_below={threshold}
        chip_color="background.main"
        text_color={color2}
        value_color={color2}
      /> */}
    </Stack>
  );
}

export default CustomPercentTable;
