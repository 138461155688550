// src/contexts/ResultsContext.js
import React, { createContext, useState } from "react";
// import dinov2resultsLOCAL from "./data/dinov2_simulation_metrics_LOCAL3.json";
// import dinov2resultsLOCALAUTO from "./data/auto_dinov2_claim_metrics.json";
// import dinov2resultsLOCALFIRE from "./data/fire_dinov2_claim_metrics.json";
// import dinov2summaryLOCALAUTO from "./data/auto_dinov2_summary_metrics.json";
// import dinov2summaryLOCALFIRE from "./data/fire_dinov2_summary_metrics.json";
// import titanresultsLOCALAUTO from "./data/auto_titan_claim_metrics.json";
// import titanresultsLOCALFIRE from "./data/fire_titan_claim_metrics.json";
// import titansummaryLOCALAUTO from "./data/auto_titan_summary_metrics.json";
// import titansummaryLOCALFIRE from "./data/fire_titan_summary_metrics.json";
import useFetchData from "./useFetchData";
import { ModelContext } from "./ModelContext";
import { useSortFilter } from "./SortFilterContext";

export const ResultsContext = createContext();

const API_RESULTS_BASE_URL =
  "https://zqhmgl85wh.execute-api.us-east-1.amazonaws.com/simulation/results";

const ResultsProvider = ({ children }) => {

  const {
    results: autoResultsDino,
    loading: autoResultsLoadingDino,
    error: autoResultsErrorDino,
  } = useFetchData(`${API_RESULTS_BASE_URL}?lob=auto&model=dinov2&type=claim`);


  const {
    results: fireResultsDino,
    loading: fireResultsLoadingDino,
    error: fireResultsErrorDino,
  } = useFetchData(`${API_RESULTS_BASE_URL}?lob=fire&model=dinov2&type=claim`);


  const {
    results: autoResultsTitan,
    // loading: autoResultsLoadingTitan,
    // error: autoResultsErrorTitan,
  } = useFetchData(`${API_RESULTS_BASE_URL}?lob=auto&model=titan&type=claim`);

  const {
    results: fireResultsTitan,
    // loading: fireResultsLoadingTitan,
    // error: fireResultsErrorTitan,
  } = useFetchData(`${API_RESULTS_BASE_URL}?lob=fire&model=titan&type=claim`);

  const [autoResults, setAutoResults] = useState(null);
  const [filteredAutoResults, setFilteredAutoResults] = useState(null);
  const [autoLoading, setAutoLoading] = useState(autoResultsLoadingDino);
  const [autoError, setAutoError] = useState(autoResultsErrorDino);

  const [fireResults, setFireResults] = useState(null);
  const [filteredFireResults, setFilteredFireResults] = useState(null);
  const [fireLoading, setFireLoading] = useState(false);
  const [fireError, setFireError] = useState(false);

  const { setFilters } = useSortFilter();
  const { modelSelected } = React.useContext(ModelContext);

  const resetResults = () => {
    if (modelSelected === "dinov2") {
      setAutoResults(autoResultsDino);
      setFireResults(fireResultsDino);
      setFilteredAutoResults(autoResultsDino);
      setFilteredFireResults(fireResultsDino);
    } else {
      setAutoResults(autoResultsTitan);
      setFireResults(fireResultsTitan);
      setFilteredAutoResults(autoResultsTitan);
      setFilteredFireResults(fireResultsTitan);
    }
    setFilters({
      valid_docs_only: false,
      pd_tasks_only: false,
      sha_excluded_only: false,
    });
  };

  React.useEffect(() => {
    // console.log("ResultsProvider useEffect Auto");
    // console.log(`Loading value = ${autoResultsLoadingDino}`);
    setAutoResults(autoResultsDino);
    setFilteredAutoResults(autoResultsDino);
    setAutoLoading(autoResultsLoadingDino);
  }, [autoResultsLoadingDino]);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // console.log("ResultsProvider useEffect Fire");
    // console.log(`Loading value = ${fireResultsLoadingDino}`);
    setFireResults(fireResultsDino);
    setFilteredFireResults(fireResultsDino);
    setFireLoading(fireResultsLoadingDino);
  }, [fireResultsLoadingDino]);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // console.log("ResultsProvider error useEffect Auto");
    // console.log(`Error value = ${autoResultsErrorDino}`);
    setAutoError(autoResultsErrorDino);  }, 
    [autoResultsErrorDino]);

  React.useEffect(() => {
    // console.log("ResultsProvider error useEffect Fire");
    // console.log(`Error value = ${fireResultsErrorDino}`);
    setFireError(fireResultsErrorDino);
  }, [fireResultsErrorDino]);

  // React.useEffect(() => {
  //   console.log("ResultsProvider useEffect Auto summary");
  //   console.log(`Loading value = ${autoSummaryLoadingDino}`);
  //   setAutoSummary(autoSummaryDino);
  //   setAutoSummaryLoading(autoSummaryLoadingDino);
  // }, [autoResultsLoadingDino]);

  // React.useEffect(() => {
  //   console.log("ResultsProvider useEffect Fire summary");
  //   console.log(`Loading value = ${fireSummaryLoadingDino}`);
  //   setFireSummary(fireSummaryDino);
  //   setFireSummaryLoading(fireSummaryLoadingDino);
  // }, [fireSummaryLoadingDino]);

  React.useEffect(() => {
    if (modelSelected === "dinov2") {
      console.log("Model changed to DINO!");
      setAutoResults(autoResultsDino);
      setFireResults(fireResultsDino);
      setFilteredAutoResults(autoResultsDino);
      setFilteredFireResults(fireResultsDino);
    } else {
      console.log("Model changed to TITAN!");
      
      setAutoResults(autoResultsTitan);
      setFireResults(fireResultsTitan);
      setFilteredAutoResults(autoResultsTitan);
      setFilteredFireResults(fireResultsTitan);
    }
    resetResults();
  }, [modelSelected]);// eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const accessTokenRequest = {
  //     scopes: loginRequest.scopes,
  //     account: accounts[0],
  //   };

  //   instance
  //     .acquireTokenSilent(accessTokenRequest)
  //     .then((accessTokenResponse) => {
  //       setBearerToken(accessTokenResponse.accessToken);
  //     });
  // });
  //   useEffect(() => {
  //     fetch(
  //       "https://zqhmgl85wh.execute-api.us-east-1.amazonaws.com/results/dinov2results"
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setResults(data);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         setError(error);
  //         setLoading(false);
  //       });
  //   }, []);

  return (
    <ResultsContext.Provider
      value={{
        autoResults,
        filteredAutoResults,
        setFilteredAutoResults,
        autoLoading,
        autoError,
        fireResults,
        filteredFireResults,
        setFilteredFireResults,
        fireLoading,
        fireError,
        resetResults,
      }}
    >
      {children}`{" "}
    </ResultsContext.Provider>
  );
};

export default ResultsProvider;
