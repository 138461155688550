import {
  Box,
  Grid2,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import SortMenu from "./SortMenu";
import FilterMenu from "./FilterMenu";
import ClaimRow from "./ClaimRow";
import { useNewMailMode } from "../NewMailModeContext";
import NewMailModeToggle from "./NewMailModeToggle";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ModelContext } from "../ModelContext";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ border: 0, flexShrink: 0, ml: 2 }}>
      <IconButton
        sx={{ color: "secondary.main" }}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        sx={{ color: "secondary.main" }}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        sx={{ color: "secondary.main" }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        sx={{ color: "secondary.main" }}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function SimResultsList({
  type_of_claim,
  data,
  setFilteredResults,
  originalData,
}) {
  const { nm_to_all, handle_new_mail_mode_change } = useNewMailMode();

  const { modelSelected } = useContext(ModelContext);

  const thresholds =
    data.length > 0
      ? nm_to_all
        ? Object.entries(data[0].total_tasks.new_mail_to_all)
        : Object.entries(data[0].total_tasks.new_mail_to_new_mail)
      : [];

  const columnsCount = 2 + Object.keys(thresholds).length;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Ref and state for dynamically calculating the height of the first header row
  const firstHeaderRef = useRef(null);
  const [firstHeaderHeight, setFirstHeaderHeight] = React.useState(0);

  useEffect(() => {
    if (firstHeaderRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setFirstHeaderHeight(firstHeaderRef.current.offsetHeight);
      });

      resizeObserver.observe(firstHeaderRef.current);

      return () => resizeObserver.disconnect();
    }
  }, [firstHeaderRef]);
  return (
    <Box
      sx={{
        border: 5,
        borderRadius: 3,
        borderColor: "lightgray",
        width: "100%",
        height: "100%",
        // maxHeight: "85xvh",
        minWidth: 700,
        position: "relative",
      }}
    >
      <TableContainer
        sx={{
          // border: 5,
          // borderRadius: 3,
          // borderColor: "lightgray",
          // width: "100%",
          maxHeight: "78vh",
          // minWidth: 700,
        }}
      >
        <Table stickyHeader>
          <TableHead colSpan={columnsCount}>
            <TableRow
              ref={firstHeaderRef}
              colSpan={columnsCount}
              sx={{ position: "sticky", zIndex: 1 }}
            >
              <TableCell
                size="small"
                colSpan={columnsCount}
                align="center"
                sx={{
                  py: 1,
                  bgcolor: "lightgray",
                  borderBottom: 0,
                  borderColor: "secondary.main",
                }}
              >
                <Grid2 container>
                  <Grid2
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                    size="grow"
                  >
                    <Stack spacing={1}>
                      <SortMenu
                        data={data}
                        setFilteredResults={setFilteredResults}
                        // resetResults={resetResults}
                        thresholds={thresholds}
                      />
                      <FilterMenu
                        originalData={originalData}
                        setFilteredResults={setFilteredResults}
                        // resetResults={resetResults}
                        thresholds={thresholds}
                      />
                    </Stack>
                  </Grid2>
                  <Grid2
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "secondary.main",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography sx={{ fontWeight: "bold", fontSize: 26 }}>
                        {type_of_claim} Results
                      </Typography>
                      <Stack spacing={1} direction={"row"}>
                        <Typography
                          sx={{ fontSize: 16, color: "secondary.main" }}
                        >
                          {data.length} Claim{data.length === 1 ? "" : "s"}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 16, color: "secondary.main" }}
                        >
                          {" \u2022 "}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 16, color: "secondary.main" }}
                        >
                          {nm_to_all
                            ? "New Mail to All"
                            : "New Mail to New Mail"}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 16, color: "secondary.main" }}
                        >
                          {" \u2022 "}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 16, color: "secondary.main" }}
                        >
                          {modelSelected === "dinov2" ? "DinoV2" : "Titan"}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid2>
                  <Grid2
                    size="grow"
                    sx={{
                      justifyContent: "right",
                      display: "flex",
                      alignItems: "right",
                    }}
                  >
                    <NewMailModeToggle />
                  </Grid2>
                </Grid2>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                width: "100%",
                top: firstHeaderHeight,
                position: "sticky",
                zIndex: 1,
              }}
            >
              <TableCell
                align="center"
                colSpan={2}
                sx={{ p: 0, borderBottom: 3, bgcolor: "lightgray" }}
              ></TableCell>
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  p: 0,
                  borderLeft: 1,
                  borderLeftColor: "gray",
                  borderBottom: 3,
                  bgcolor: "lightgray",
                }}
              >
                <Typography sx={{ pb: 1, fontSize: 14 }}>
                  Number of Similar Files Identified
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((item) => (
              <ClaimRow
                hover={true}
                key={item.claim_id}
                item={item}
                columnsCount={columnsCount}
                nm_to_all={nm_to_all}
                handle_new_mail_mode_change={handle_new_mail_mode_change}
                thresholds={thresholds}
                back_link={type_of_claim === "Auto" ? "/" : "/fire"}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={columnsCount} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          backgroundColor: "lightgray",
          // backgroundColor: "white",
          borderTop: 3,
          borderTopColor: "secondary.main",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[
            10,
            25,
            50,
            100,
            500,
            { label: "All", value: -1 },
          ]}
          colSpan={columnsCount}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            select: {
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            },
          }}
          sx={{
            p: 0,
            m: 0,
            // backgroundColor: "lightgray",
            border: 0,
            display: "flex",
            justifyContent: "center",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
    </Box>
  );
}

export default SimResultsList;
