import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid2,
  ImageList,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ClaimRowChip from "./ClaimRowChip";
import { useTheme } from "@emotion/react";
import PDImageListItem from "./PDImageListItem";

// const chosen_threshold = "900";
function get_default_threshold(tasks_info) {
  // pick the middle threshold
  const keys = Object.keys(tasks_info);
  const midIndex = Math.ceil(keys.length / 2) - 1; // round up so we would pick the 2nd element in a 3-element array.  subtract 1 because 0-based index
  return keys[midIndex];
}

const PDListModal = React.forwardRef((props, ref) => {
  const {
    claim_id,
    tasks_info,
    num_valid_docs,
    date,
    setClosePDTasks,
    threshold_colors,
  } = props;

  const theme = useTheme();
  // const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  let currentBreakpointCols = 1;
  if (isXl) currentBreakpointCols = 3;
  else if (isLg) currentBreakpointCols = 3;
  else if (isMd) currentBreakpointCols = 2;
  else if (isSm) currentBreakpointCols = 2;

  let maxLength = 0;
  let default_threshold = get_default_threshold(tasks_info);
  if (tasks_info[default_threshold].length === 0) {
    for (const threshold in tasks_info) {
      const currentList = tasks_info[threshold];
      if (currentList.length > maxLength) {
        maxLength = currentList.length;
        default_threshold = threshold;
      }
    }
  }

  const [selectedThreshold, setselectedThreshold] =
    React.useState(default_threshold);
  return (
    <Box
      ref={ref}
      tabIndex={-1} // Ensure the Box is focusable
      sx={{
        position: "fixed",
        // ml: { xs: "0px", ml: "175px" },
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
        // overflow: "scroll",
        width: { xs: "100%", md: `calc(100% - 175px)` },
        bgcolor: "background.default",
        border: 0,
        borderRadius: 0,
        boxShadow: 10,
        p: 2,
        mt: 8,
      }}
    >
      <Box sx={{ p: 2, border: 5, borderColor: "lightgray", borderRadius: 3 }}>
        <Grid2 container>
          <Grid2 size="grow">
            <Button
              variant="contained"
              onClick={setClosePDTasks}
              sx={{
                borderRadius: 3,
                textTransform: "unset",
                backgroundColor: "primary.main",
              }}
              startIcon={<ClearIcon />}
            >
              CLOSE
            </Button>
          </Grid2>
          <Grid2>
            <Stack spacing={1} sx={{ alignItems: "center" }}>
              <Stack direction={"row"}>
                <Typography
                  sx={{ py: 0, pr: 1, fontSize: 16, fontWeight: "light" }}
                >
                  Similar Files Identified for:
                </Typography>
                <Typography
                  sx={{ py: 0, pr: 1, fontSize: 16, fontWeight: "bold" }}
                >
                  {claim_id}
                </Typography>
                {/* <Typography sx={{fontSize: 16}}>Claim ID:</Typography> */}
              </Stack>
              <Stack
                sx={{ alignItems: "center" }}
                spacing={1}
                direction={"row"}
              >
                <Typography sx={{ py: 0, fontSize: 16, fontWeight: "bold" }}>
                  {date}
                </Typography>
                <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
                <ClaimRowChip
                  text={"Valid Files"}
                  value={num_valid_docs}
                  fontSize={10}
                  valueSize={14}
                  chip_width={80}
                  chip_color={"#8fab87"}
                />
                {/* {num_valid_docs} Valid Doc IDs */}
                <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
                {Object.entries(tasks_info).map(
                  ([threshold, tasks_list], index) => (
                    <Stack sx={{ alignItems: "center" }} key={threshold}>
                      <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                        0.{threshold}
                      </Typography>
                      <ClaimRowChip
                        // text={"PD Tasks"}
                        value={tasks_list.length}
                        fontSize={10}
                        valueSize={20}
                        chip_width={50}
                        chip_color={threshold_colors[index]}
                      />
                    </Stack>
                  )
                )}
              </Stack>
            </Stack>
          </Grid2>
          <Grid2 size="grow" />
        </Grid2>
        <Stack
          sx={{ pt: 1, alignItems: "center" }}
          spacing={1}
          direction={"row"}
        >
          <Typography> Pick Threshold: </Typography>
          <ButtonGroup
            variant="outlined"
            aria-label="Threshold choices"
            sx={{ borderRadius: 3, borderColor: "secondary.main" }}
          >
            {Object.entries(tasks_info).map(
              ([threshold, tasks_list], index) => (
                <Button
                  key={threshold}
                  disabled={tasks_list.length === 0}
                  onClick={() => setselectedThreshold(threshold)}
                  sx={{
                    borderRadius: 3,
                    fontWeight: "bold",
                    bgcolor:
                      selectedThreshold === threshold
                        ? "secondary.main"
                        : "background.default",
                    "&:disabled": {
                      borderColor: "secondary.main",
                      fontWeight: "light",
                    },
                    color:
                      selectedThreshold === threshold
                        ? "white"
                        : "secondary.main",

                    borderColor: "secondary.main",
                  }}
                >
                  0.{threshold}
                </Button>
              )
            )}
          </ButtonGroup>
        </Stack>
        <Divider sx={{ py: 0.5, width: "100%", color: "gray" }}>
          Showing {tasks_info[selectedThreshold].length} Similar Files Identified {" \u2022 "}
          Threshold=0.{selectedThreshold}
        </Divider>

        <Box sx={{ height: "500px", overflowY: "auto", mt: 2 }}>
          <ImageList cols={currentBreakpointCols} sx={{ overflow: "auto" }}>
            {tasks_info[selectedThreshold].map((task, task_index) => (
              <PDImageListItem
                key={task_index}
                task_index={task_index}
                img1={task[0][0]}
                img2={task[0][1]}
                num_pages={task.length}
                threshold={selectedThreshold}
                date={date}
              />
            ))}
            {/* {tasks_info[selectedThreshold].concat(tasks_info[selectedThreshold]).map((task, task_index) => (
            <PDImage
              task_index={task_index}
              img1={task[0][0]}
              img2={task[0][1]}
              num_pages={task.length}
            />
          ))}
           {tasks_info[selectedThreshold].concat(tasks_info[selectedThreshold]).map((task, task_index) => (
            <PDImage
              task_index={task_index}
              img1={task[0][0]}
              img2={task[0][1]}
              num_pages={task.length}
            />
          ))}
           {tasks_info[selectedThreshold].concat(tasks_info[selectedThreshold]).map((task, task_index) => (
            <PDImage
              task_index={task_index}
              img1={task[0][0]}
              img2={task[0][1]}
              num_pages={task.length}
            />
          ))} */}
          </ImageList>
        </Box>
      </Box>
    </Box>
  );
});

export default PDListModal;
