import { Box, Stack, Typography, Grid2 } from "@mui/material";
import React from "react";
import DoubleChart from "./DoubleChart";
import CustomKPI from "./CustomKPI";
import CustomPercentTable from "./CustomPercentTable";
import CustomLegend from "./CustomLegend";
import { blue, orange } from "@mui/material/colors";
import CustomBarChart from "./CustomBarChart";
import CustomNumberTable from "./CustomNumberTable";
import { ModelContext } from "../ModelContext";

function SummaryDashboard({
  autoSummary,
  fireSummary,
  autoThresholds,
  fireThresholds,
  autoTasksChartData,
  fireTasksChartData,
  autoMirrorChartData,
  fireRoofChartData,
  autoNewDocMatchMultipleDocsChartData,
  fireNewDocMatchMultipleDocsChartData,
}) {
  const { modelSelected } = React.useContext(ModelContext);

  return (
    <Stack
      sx={{ border: 0, p: 1, display: "flex", justifyContent: "center", overflowX: "auto" }}
      spacing={1}
    >
      <Stack
        spacing={1}
        direction={"row"}
        sx={{
          justifyContent: "center",
          width: "100%",
          display: "flex",
          border: 0,
        }}
      >
        <DoubleChart
          maxWidth={400}
          minWidth={210}
          title={"AVG timespan of Claim"}
          chart1={
            <CustomKPI
              title={"Auto"}
              value={autoSummary.avg_overall_timespan.toFixed(1)}
              text_below={"Days"}
              chip_color="background.main"
              text_color={blue[900]}
              value_color={blue[900]}
            />
          }
          chart2={
            <CustomKPI
              title={"Fire"}
              value={fireSummary.avg_overall_timespan.toFixed(1)}
              text_below={"Days"}
              chip_color={"background.main"}
              text_color={orange[900]}
              value_color={orange[900]}
            />
          }
        />
        <DoubleChart
          maxWidth={400}
          minWidth={330}
          title={"Total Files excluded by FCM"}
          chart1={
            <CustomKPI
              title={"Auto"}
              value={autoSummary.sum_fcm_excluded.toLocaleString()}
              text_below={`AVG/Claim = ${autoSummary.avg_fcm_excluded.toFixed(
                1
              )}`}
              chip_color="background.main"
              text_color={blue[900]}
              value_color={blue[900]}
            />
          }
          chart2={
            <CustomKPI
              title={"Fire"}
              value={fireSummary.sum_fcm_excluded.toLocaleString()}
              text_below={`AVG/Claim = ${fireSummary.avg_fcm_excluded.toFixed(
                1
              )}`}
              chip_color="background.main"
              text_color={orange[900]}
              value_color={orange[900]}
            />
          }
        />
        <DoubleChart
          maxWidth={400}
          minWidth={330}
          title={"Total Files excluded by SHA"}
          chart1={
            <CustomKPI
              title={"Auto"}
              value={autoSummary.sum_sha_excluded.toLocaleString()}
              text_below={`AVG/Claim = ${autoSummary.avg_sha_excluded.toFixed(
                1
              )}`}
              chip_color="background.main"
              text_color={blue[900]}
              value_color={blue[900]}
            />
          }
          chart2={
            <CustomKPI
              title={"Fire"}
              value={fireSummary.sum_sha_excluded.toLocaleString()}
              text_below={`AVG/Claim = ${fireSummary.avg_sha_excluded.toFixed(
                1
              )}`}
              chip_color="background.main"
              text_color={orange[900]}
              value_color={orange[900]}
            />
          }
        />
      </Stack>
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DoubleChart
          maxWidth={"100%"}
          minWidth={"100%"}
          title={"Overall % of Images in Claims that generate a Potential Duplicate"}
          chart1={
            <Box
              sx={{
                width: "50%",
                borderRight: 1,
                borderColor: "gray",
              }}
            >
              <Typography
                align="center"
                sx={{ pb: 1, fontWeight: "bold", fontSize: 14 }}
              >
                Auto
              </Typography>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {autoThresholds.map((threshold, index) => (
                  <CustomPercentTable
                    key={index}
                    threshold={threshold}
                    type_of_claim={"auto"}
                    model={modelSelected}
                    dataSummary={autoSummary}
                    color1={blue[900]}
                    color2={blue[600]}
                    borderRight={index === autoThresholds.length - 1 ? 0 : 2}
                  />
                ))}
              </Stack>
              <CustomLegend
                color1={blue[900]}
                text1={"New Mail to All"}
                color2={blue[600]}
                text2={"New Mail to New Mail"}
                color3={"gray"}
                text3={"All to All"}
              />
            </Box>
          }
          chart2={
            <Box sx={{ width: "50%" }}>
              <Typography
                align="center"
                sx={{ pb: 1, fontWeight: "bold", fontSize: 14 }}
              >
                Fire
              </Typography>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {fireThresholds.map((threshold, index) => (
                  <CustomPercentTable
                    key={index}
                    threshold={threshold}
                    type_of_claim={"fire"}
                    model={modelSelected}
                    dataSummary={fireSummary}
                    color1={orange[900]}
                    color2={orange[600]}
                    borderRight={index === fireThresholds.length - 1 ? 0 : 2}
                  />
                ))}
              </Stack>
              <CustomLegend
                color1={orange[900]}
                text1={"New Mail to All"}
                color2={orange[600]}
                text2={"New Mail to New Mail"}
                color3={"gray"}
                text3={"All to All"}
              />
            </Box>
          }
        />
        <Grid2 container sx={{ width: "100%" }}>
          <Grid2 size="grow" sx={{ pr: 1 }}>
            <DoubleChart
              title={
                "Total Potential Duplicates generated for all Claims by threshold"
              }
              maxWidth={1150}
              chart1={
                <CustomBarChart
                  title={"Auto"}
                  data={autoTasksChartData}
                  dataKey_xaxis={"name"}
                  dataKey_yaxis1={"New Mail to All"}
                  dataKey_yaxis2={"New Mail to New Mail"}
                  color1={blue[900]}
                  color2={blue[600]}
                />
              }
              chart2={
                <CustomBarChart
                  title={"Fire"}
                  data={fireTasksChartData}
                  dataKey_xaxis={"name"}
                  dataKey_yaxis1={"New Mail to All"}
                  dataKey_yaxis2={"New Mail to New Mail"}
                  color1={orange[900]}
                  color2={orange[600]}
                />
              }
            />
          </Grid2>
          <Grid2 size="grow">
            <DoubleChart
              maxWidth={1150}
              title={
                "Total Mirror / Roof Model Exclusions for all Claims by threshold"
              }
              chart1={
                <CustomBarChart
                  title={"Mirror Model"}
                  data={autoMirrorChartData}
                  dataKey_xaxis={"name"}
                  dataKey_yaxis1={"New Mail to All"}
                  dataKey_yaxis2={"New Mail to New Mail"}
                  color1={blue[900]}
                  color2={blue[600]}
                />
              }
              chart2={
                <CustomBarChart
                  title={"Roof Model"}
                  data={fireRoofChartData}
                  dataKey_xaxis={"name"}
                  dataKey_yaxis1={"New Mail to All"}
                  dataKey_yaxis2={"New Mail to New Mail"}
                  color1={orange[900]}
                  color2={orange[600]}
                />
              }
            />
          </Grid2>
        </Grid2>
      </Stack>
      <DoubleChart
          maxWidth={"100%"}
          minWidth={"100%"}
          title={"Number of times a new doc matched multiple existing docs on a claim"}
          chart1={
            <Box
              sx={{
                width: "50%",
                borderRight: 1,
                borderColor: "gray",
              }}
            >
              <Typography
                align="center"
                sx={{ pb: 1, fontWeight: "bold", fontSize: 14 }}
              >
                Auto
              </Typography>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {autoThresholds.map((threshold, index) => (
                  <CustomNumberTable
                    key={index}
                    threshold={threshold}
                    dataSummary={autoSummary}
                    color1={blue[900]}
                    color2={blue[600]}
                    borderRight={index === autoThresholds.length - 1 ? 0 : 2}
                  />
                ))}
              </Stack>
              <CustomLegend
                color1={blue[900]}
                text1={"New Mail to All"}
                color2={blue[600]}
                text2={"New Mail to New Mail"}
              />
            </Box>
          }
          chart2={
            <Box sx={{ width: "50%" }}>
              <Typography
                align="center"
                sx={{ pb: 1, fontWeight: "bold", fontSize: 14 }}
              >
                Fire
              </Typography>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {fireThresholds.map((threshold, index) => (
                  <CustomNumberTable
                    key={index}
                    threshold={threshold}
                    dataSummary={fireSummary}
                    color1={orange[900]}
                    color2={orange[600]}
                    borderRight={index === fireThresholds.length - 1 ? 0 : 2}
                  />
                ))}
              </Stack>
              <CustomLegend
                color1={orange[900]}
                text1={"New Mail to All"}
                color2={orange[600]}
                text2={"New Mail to New Mail"}
              />
            </Box>
          }
        />
      
    </Stack>
  );
}

export default SummaryDashboard;
