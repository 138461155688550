import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

function CustomBarChart({
  title,
  data,
  dataKey_xaxis,
  dataKey_yaxis1,
  dataKey_yaxis2,
  color1,
  color2,
  borderRight = 0
}) {
  const borderRadius = 0;
  return (
    <Stack
      sx={{
        width: "100%",
        borderRadius: borderRadius,
        border: 0,
        borderColor: "lightgray",
        borderRight:borderRight
      }}
    >
      <Typography
        align="center"
        sx={{
          py: 0.5,
          fontSize: 14,
          fontWeight: "bold",
          color: "secondary.main",
        }}
      >
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={160}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 20, left: 0, bottom: 5 }}
          barGap={0} // Remove gap between bars within a category
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={dataKey_xaxis}
            tick={{ fontSize: 16, fontWeight: "bold" }}
          />
          <YAxis tick={{ fontSize: 14 }} />
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: 14 }} />
          <Bar dataKey={dataKey_yaxis1} fill={color1}>
            <LabelList
              dataKey={dataKey_yaxis1}
              position="top"
              style={{ fontSize: 14 }}
            />
          </Bar>
          <Bar dataKey={dataKey_yaxis2} fill={color2}>
            <LabelList
              dataKey={dataKey_yaxis2}
              position="top"
              style={{ fontSize: 14 }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
}

export default CustomBarChart;
