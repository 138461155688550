import { Stack, Typography } from "@mui/material";
import React from "react";
import ClaimRowChip from "../components/ClaimRowChip";

function CustomKPI({
  title,
  value,
  text_below,
  text_color,
  value_color,
  chip_color,
}) {
  return (
    <Stack
      sx={{
        width: "50%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          pt: 0.5,
          fontSize: 14,
          fontWeight: "bold",
          color: "secondary.main",
        }}
        align="center"
      >
        {title}
      </Typography>
      <ClaimRowChip
        chip_width="100%"
        text={text_below}
        value={value}
        chip_color={chip_color}
        text_color={text_color}
        value_color={value_color}
        valueSize={28}
        fontSize={16}
      />
    </Stack>
  );
}

export default CustomKPI;
