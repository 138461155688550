import {
  Box,
  Button,
  ImageListItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PDImage from "./PDImage";
import ClearIcon from "@mui/icons-material/Clear";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxHeight: "90%",
  maxWidth: "1600px",
  bgcolor: "background.default",
  border: "1px solid #000",
  borderRadius: 5,
  boxShadow: 10,
  p: 3,
  mt: 2,
};

function PDImageListItem({ task_index, img1, img2, num_pages = 1 }) {
  const [openImage, setOpenImage] = React.useState(false);
  const [selectedImages, setSelectedImages] = React.useState(null);
  const [task_num, setTaskNum] = React.useState(null);

  const handleImageClick = (imageSrc1, imgSrc2, selected_task_num) => {
    setSelectedImages([imageSrc1, imgSrc2]);
    setOpenImage(true);
    setTaskNum(selected_task_num);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
    setSelectedImages(null);
    setTaskNum(null);
  };

  const [img1Data, setimg1Data] = useState(null);
  const [img2Data, setimg2Data] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { instance, accounts } = useMsal();
  const [bearerToken, setBearerToken] = useState("");
  const url =
    "https://zqhmgl85wh.execute-api.us-east-1.amazonaws.com/simulation/pddisplay";

  useEffect(() => {
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        setBearerToken(accessTokenResponse.accessToken);
      });
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!bearerToken) {
        return; // Wait until the token is acquired
      }
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
          },
          body: JSON.stringify({
            imgSrc1: img1,
            imgSrc2: img2,
          }),
        });
        const data = await response.json();
        setimg1Data(data.img1);
        setimg2Data(data.img2);
        setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [url, bearerToken, img1, img2]);

  // useEffect(() => {
  //   if (!bearerToken) {
  //     return; // Wait until the token is acquired
  //   }
  //   fetch(
  //     "https://zqhmgl85wh.execute-api.us-east-1.amazonaws.com/simulation/pddisplay",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${bearerToken}`,
  //       },
  //       body: JSON.stringify({
  //         imgSrc1: img1,
  //         imgSrc2: img2,
  //       }),
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setimg1Data(data.img1);
  //       setimg2Data(data.img2);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //       setLoading(false);
  //     });
  // });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <React.Fragment>
      <ImageListItem
        onClick={() =>
          handleImageClick(
            `${process.env.PUBLIC_URL}/images/${img1}`,
            `${process.env.PUBLIC_URL}/images/${img2}`,
            task_index + 1
          )
        }
        sx={{
          border: 1,
          borderColor: "lightgray",
          borderRadius: 1,
          p: 1,
          m: 1,
        }}
      >
        <Typography sx={{ pb: 1, fontSize: 14, fontWeight: "bold" }}>
          PD Task: {task_index + 1}
        </Typography>
        <PDImage
          img1Data={img1Data}
          img2Data={img2Data}
          task_index={task_index + 1}
        />
      </ImageListItem>
      <Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="enlarged-image-modal"
        aria-describedby="enlarged-image-modal-description"
      >
        <Box sx={imageModalStyle}>
          <Stack direction={"row"} sx={{ pb: 2, alignItems: "center" }}>
            <Button
              variant="contained"
              onClick={handleCloseImage}
              sx={{
                borderRadius: 3,
                textTransform: "unset",
                backgroundColor: "primary.main",
              }}
              startIcon={<ClearIcon />}
            >
              CLOSE
            </Button>
            <Typography
              sx={{ p: 0, pb: 0, ml: 2, fontSize: 16, fontWeight: "bold" }}
            >
              PD Task: {task_num}
            </Typography>
          </Stack>
          {selectedImages && (
            <PDImage
              img1Data={img1Data}
              img2Data={img2Data}
              task_index={task_index + 1}
            />
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default PDImageListItem;
