import { MenuItem, Stack } from "@mui/material";
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function SortRow({
  sort_key,
  text,
  sortedBy,
  handleSortClick,
  ascendingSort,
}) {
  const highlightColor = "#9e9e9e";
  return (
    <Stack direction={"row"} sx={{ borderBottom: 1, borderTop: 0 }}>
      <MenuItem
        sx={{
          borderRight: 1,
          width: "50%",
          fontWeight:
            sortedBy === sort_key
              ? ascendingSort
                ? "bold"
                : "light"
              : "light",
          backgroundColor:
            sortedBy === sort_key
              ? ascendingSort
                ? highlightColor
                : "white"
              : "white",
          "&:hover": {
            backgroundColor: highlightColor,
          },
        }}
        onClick={() => handleSortClick(sort_key, true, text)}
      >
        <ArrowUpwardIcon size="small" sx={{ width: 20, p: 0 }} />

        {text}
      </MenuItem>
      <MenuItem
        sx={{
          width: "50%",
          fontWeight:
            sortedBy === sort_key
              ? !ascendingSort
                ? "bold"
                : "light"
              : "light",
          backgroundColor:
            sortedBy === sort_key
              ? !ascendingSort
                ? highlightColor
                : "white"
              : "white",
          "&:hover": {
            backgroundColor: highlightColor,
          },
        }}
        onClick={() => handleSortClick(sort_key, false, text)}
      >
        <ArrowDownwardIcon size="small" sx={{ width: 20, p: 0 }} /> {text}
      </MenuItem>
    </Stack>
  );
}

export default SortRow;
