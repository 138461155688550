import { Box, Stack, Typography } from "@mui/material";
import React from "react";

function DoubleChart({ title, chart1, chart2, maxWidth=1000, minWidth=300 }) {
  return (
    <Box
      sx={{
        maxWidth: maxWidth,
        minWidth: minWidth,
        height: "100%",
        border: 2,
        borderColor: "lightgray",
        backgroundColor: "background.main",
        borderRadius: 3,
      }}
    >
      <Typography
        align="center"
        sx={{
          borderBottom: 1,
          py: 0.5,
          fontSize: 16,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          fontWeight: "bold",
          backgroundColor: "lightgray",
          borderColor: "gray",
          color: "secondary.main"
        }}
      >
        {title}
      </Typography>
      <Stack direction={"row"}>
        {chart1}
        {chart2}
      </Stack>
    </Box>
  );
}

export default DoubleChart;
