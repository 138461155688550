import { Box, Stack, Typography } from "@mui/material";
import React from "react";

function CustomLegend({ color1, text1, color2, text2, color3=null, text3=null }) {
  return (
    <Stack
      spacing={0.5}
      direction={"row"}
      sx={{ justifyContent: "center", alignItems: "center", px: 1, py: 1 }}
    >
      <Box sx={{ backgroundColor: color1, width: 15, height: 10 }}></Box>
      <Typography sx={{ color: color1, pr: 1 }}>{text1}</Typography>
      <Box sx={{ backgroundColor: color2, width: 15, height: 10 }}></Box>
      <Typography sx={{ color: color2 }}>{text2}</Typography>
      {color3 && text3 ? (
        <Stack spacing={0.5} direction={"row"} sx={{pl:0.5, alignItems: "center"}}>
          <Box sx={{ backgroundColor: color3, width: 15, height: 10, }}></Box>
          <Typography sx={{ color: color3, pr: 1 }}>{text3}</Typography>
        </Stack>
      ) : null}
    </Stack>
  );
}

export default CustomLegend;
