import React from "react";
import mirror_perf from "../data/mirror_performance_metrics.json";
import dinov2_perf from "../data/dinov2_performance_metrics.json";
import { Box, Stack, Typography } from "@mui/material";
import PerformanceTable from "../components/PerformanceTable";

function PerformancePage() {
  return (
    <Box sx={{ p: 2 }}>
      <Stack
        sx={{
          borderRadius: 5,
          border: 5,
          borderColor: "lightgray",
          // fontSize: 20,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Typography
          align="center"
          sx={{
            color: "secondary.main",
            borderBottom: 3,
            pt: 1,
            pb: 2,
            backgroundColor: "lightgray",
            borderColor: "secondary.main",
            // pl: 55,
            fontSize: 26,
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Simulation Technical Summary
        </Typography>
        <PerformanceTable perf_data={dinov2_perf} model={"DinoV2"}/> 
        <PerformanceTable perf_data={mirror_perf} model={"Mirror"}/> 
      </Stack>
    </Box>
  );
}

export default PerformancePage;
