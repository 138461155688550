import { Stack, Typography } from "@mui/material";
import React from "react";

function CustomNumberTable({
  threshold,
  dataSummary,
  color1,
  color2,
  borderRight,
}) {
  const nm_2_nm_sum_occurences =
    dataSummary.sum_num_times_new_doc_matched_multiple_docs_on_claim_new_mail_to_new_mail[threshold.slice(2)];
  const nm_2_all_sum_occurences =
    dataSummary.sum_num_times_new_doc_matched_multiple_docs_on_claim_new_mail_to_all[threshold.slice(2)];

  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRight: borderRight,
        borderColor: "gray",
        px: 1,
      }}
    >
      <Typography
        align="center"
        sx={{
          fontSize: 16,
          color: "gray",
          fontWeight: "bold",
          borderBottom: 1,
          pb: 0.5,
          width: "100%",
          borderColor: "gray",
        }}
      >
        {threshold}
      </Typography>
      <Typography
        align="center"
        sx={{
          py: 0.5,
          px: 1,
          fontSize: {xs: 14, sm:22, md:24 },
          color: color1,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {nm_2_all_sum_occurences}
      </Typography>
      <Typography
        align="center"
        sx={{
          py: 0.5,
          px: 1,
          fontSize: {xs: 14, sm:22, md:24 },
          color: color2,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {nm_2_nm_sum_occurences}
      </Typography>
    </Stack>
  );
}

export default CustomNumberTable;
