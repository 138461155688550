import {
  Box,
  Button,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useNewMailMode } from "../NewMailModeContext";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import SortRow from "./SortRow";
import { useSortFilter } from "../SortFilterContext";
import { ModelContext } from "../ModelContext";

function SortMenu({
  data,
  setFilteredResults,
  // filters,
  thresholds,
}) {
  const { nm_to_all } = useNewMailMode();
  const { modelSelected } = React.useContext(ModelContext);

  const {
    ascendingSort,
    setAscendingSort,
    sortedBy,
    setSortedBy,
    textToDisplay,
    setTextToDisplay,
    filters
  } = useSortFilter();
  // const [sortedBy, setSortedBy] = React.useState("id");
  // const [ascendingSort, setAscendingSort] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleSortClick(field, asc, text) {
    setAscendingSort(asc);
    setSortedBy(field);
    setTextToDisplay(text);
    handleMenuClose();
  }

  // React.useEffect(() => {
  //   setAscendingSort(true);
  //   setSortedBy("id");
  //   setTextToDisplay("ID");
  //   handleMenuClose();
  // }, [modelSelected, setAscendingSort, setSortedBy,setTextToDisplay]);
  // const [textToDisplay, setTextToDisplay] = React.useState("ID");
  
  React.useEffect(() => {
    // console.log('SORTING RESULTS!')
    const sortedResults = [...data]; // Create a new array to avoid mutating the original array
    if (sortedBy === "id") {
      // HANDLE ID SORTS
      sortedResults.sort((first, second) => {
        return ascendingSort
          ? first.claim_id.localeCompare(second.claim_id)
          : second.claim_id.localeCompare(first.claim_id);
      });
    } else if (sortedBy.startsWith("validdocs")) {
      // HANDLE VALID DOC ID SORTS
      sortedResults.sort((first, second) => {
        return ascendingSort
          ? first.num_valid_doc_ids - second.num_valid_doc_ids
          : second.num_valid_doc_ids - first.num_valid_doc_ids;
      });
    } else if (sortedBy.startsWith("tasks")) {
      const sorted_thresh = sortedBy.slice(5);
      sortedResults.sort((first, second) => {
        if (nm_to_all) {
          return ascendingSort
            ? first.total_tasks.new_mail_to_all[sorted_thresh] -
                second.total_tasks.new_mail_to_all[sorted_thresh]
            : second.total_tasks.new_mail_to_all[sorted_thresh] -
                first.total_tasks.new_mail_to_all[sorted_thresh];
        } else {
          return ascendingSort
            ? first.total_tasks.new_mail_to_new_mail[sorted_thresh] -
                second.total_tasks.new_mail_to_new_mail[sorted_thresh]
            : second.total_tasks.new_mail_to_new_mail[sorted_thresh] -
                first.total_tasks.new_mail_to_new_mail[sorted_thresh];
        }
      });
    }
    setFilteredResults(sortedResults); // Update state with the new sorted array
  }, [sortedBy, ascendingSort, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ display: "flex", justifyContent: "left" }}>
      {/* <Typography>
        SORTED BY: {sortedBy} {ascendingSort ? "ASC" : "DESC"}
      </Typography> */}
      <Stack spacing={1} sx={{ alignItems: "center" }} direction={"row"}>
        <Button
          startIcon={<SwapVertIcon />}
          onClick={handleMenuClick}
          variant="contained"
          sx={{
            width: 110,
            height: 35,
            // borderColor: "secondary.main",
            color: "white",
            borderRadius: 3,
            textTransform: "unset",
            backgroundColor: "secondary.main",
          }}
        >
          SORT
        </Button>

        {/* <Typography sx={{fontSize: 12 }}>Sorted By: </Typography> */}
        <Typography
          sx={{
            p: 0.5,
            display: "flex",
            alignItems: "center",
            fontSize: { xs: 8, sm: 12 },
          }}
        >
          [
          {ascendingSort ? (
            <ArrowUpwardIcon sx={{ fontSize: { xs: 8, sm: 16 } }} />
          ) : (
            <ArrowDownwardIcon sx={{ fontSize: { xs: 8, sm: 16 } }} />
          )}{" "}
          {textToDisplay} ]
        </Typography>
      </Stack>

      <Menu
        id="sort-menu"
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        elevation={20}
      >
        <Box sx={{ display: "flex", justifyContent: "center", border: 0 }}>
          <Stack sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{ borderBottom: 1, fontWeight: "bold", pb: 1 }}
              align="center"
            >
              Sort By:
            </Typography>
            <SortRow
              sort_key={"id"}
              text={"ID"}
              sortedBy={sortedBy}
              ascendingSort={ascendingSort}
              handleSortClick={handleSortClick}
            />
            <SortRow
              sort_key={"validdocs"}
              text={"# Valid Doc IDs"}
              sortedBy={sortedBy}
              ascendingSort={ascendingSort}
              handleSortClick={handleSortClick}
            />
            {thresholds.map(([threshold, num_tasks], index) => (
              <SortRow
                key={threshold}
                sort_key={"tasks" + threshold}
                text={"# PD Tasks (0." + threshold + ")"}
                sortedBy={sortedBy}
                ascendingSort={ascendingSort}
                handleSortClick={handleSortClick}
              />
            ))}
          </Stack>
        </Box>
      </Menu>
    </Box>
  );
}

export default SortMenu;
